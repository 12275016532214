import * as React from "react";

import './Header.css';

export default function Header() {
    return (
      <h1 style={{textAlign: 'center' }}>
        Dota 2 Leaderboards
      </h1>
    );
  }